/* eslint-disable @typescript-eslint/no-empty-function */
import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString,
  formatDate,
  formatDateNormal,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import { StiManifestData, STIReverseScanDetail } from "@/domain/entities/STI";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { AccountController } from "@/app/ui/controllers/AccountController";

let doc: any;
let translate: any = () => { };
const columnStyles: any = {
  0: { cellWidth: 10 },
  1: { cellWidth: 23 },
  2: { cellWidth: 33 },
  3: { cellWidth: 21 },
  4: { cellWidth: 24 },
  5: { cellWidth: 15 },
  6: { cellWidth: 15 },
  7: { cellWidth: 17 },
  8: { cellWidth: 20 },
  9: { cellWidth: 23 },
};

const needToStiColumnStyles = {
  0: { cellWidth: 12 },
  1: { cellWidth: 30 },
  2: { cellWidth: 30 },
  3: { cellWidth: 30 },
  4: { cellWidth: 25 },
  5: { cellWidth: 16 },
  6: { cellWidth: 16 },
  7: { cellWidth: 45 }
};

const drawCellConditions = (cell: any) => [
  cell.column.dataKey === "totalPieces" &&
  (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "productType" && cell.row.section === "head",
  cell.column.dataKey === "productType" && cell.row.section === "body",
  cell.column.dataKey === "destCity" && cell.row.section === "body",
  cell.column.dataKey === "sttNumber" && cell.row.section === "body",
  cell.column.dataKey === "sttNo" && cell.row.section === "body",
];

const drawCellData = (cell: any) => {
  const productIdx = 4;
  const totalPiecesIdx = 5
  const destCityIdx = 7;
  const regionIdx = 8

  if (cell.row.index < 0) {
    return;
  }

  if (drawCellConditions(cell)[0]) {
    cell.row.cells[totalPiecesIdx].styles = {
      ...cell.row.cells[totalPiecesIdx].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[productIdx].styles = {
      ...cell.row.cells[productIdx].styles,
      cellPadding: {
        ...cell.row.cells[productIdx].styles.cellPadding,
        left: 0.7
      }
    };
  }

  //product
  if (drawCellConditions(cell)[2]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[productIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[productIdx].x + 0.7,
          cell.row.cells[productIdx].y +
          cell.row.cells[productIdx].contentHeight /
          (1.6333 +
            1.75 *
            cell.row.cells[productIdx].text.filter(
              (item: any) => item !== ""
            ).length) +
          (0.0083 + 5.675 * index),
          cell.row.cells[productIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  // dest city
  if (drawCellConditions(cell)[3]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[destCityIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[destCityIdx].x + 0.7,
          cell.row.cells[destCityIdx].y +
          cell.row.cells[destCityIdx].contentHeight /
          (1.6333 +
            1.75 *
            cell.row.cells[destCityIdx].text.filter(
              (item: any) => item !== ""
            ).length) +
          (0.0083 + 5.675 * index),
          cell.row.cells[destCityIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }

  if (drawCellConditions(cell)[4]) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const img = require("@/app/ui/assets/images/dg-chips.png");
    if (cell.row.cells[2].text[0].includes(".")) {
      doc.addImage(
        img,
        "PNG",
        cell.cell.x + 27,
        cell.cell.y + 3,
        5.5,
        4,
        "dg",
        "NONE"
      );
    }
  }
};

const drawCellNeedToStiData = (cell: any) => {
  const productIdx = 4;
  const totalPiecesIdx = 5;
  const destCityIdx = 6;

  if (cell.row.index < 0) {
    return;
  }
  if (drawCellConditions(cell)[0]) {
    cell.row.cells[totalPiecesIdx].styles = {
      ...cell.row.cells[totalPiecesIdx].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[productIdx].styles = {
      ...cell.row.cells[productIdx].styles,
      cellPadding: {
        ...cell.row.cells[productIdx].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[4]) {
    Object.keys(cell.row.cells).forEach(key => {
      Object.keys(cell.row.cells[key].text).forEach(text => {
        cell.row.cells[key].text[text] =
          cell.row.cells[key].text[parseInt(text) + 1] || "";
      });
    });
  }
  //product
  if (drawCellConditions(cell)[2]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[productIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[productIdx].x + 0.7,
          cell.row.cells[productIdx].y +
          cell.row.cells[productIdx].contentHeight /
          (1.6333 +
            1.75 *
            cell.row.cells[productIdx].text.filter(
              (item: any) => item !== ""
            ).length) +
          (0.0083 + 5.675 * index),
          cell.row.cells[productIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  // dest city
  if (drawCellConditions(cell)[3]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[destCityIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[destCityIdx].x + 0.7,
          cell.row.cells[destCityIdx].y +
          cell.row.cells[destCityIdx].contentHeight /
          (1.6333 +
            1.75 *
            cell.row.cells[destCityIdx].text.filter(
              (item: any) => item !== ""
            ).length) +
          (0.0083 + 5.675 * index),
          cell.row.cells[destCityIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const manifestPdfV2 = (
  dataHead: StiManifestData,
  data: Array<any>,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`${translate("STI Manifest")} - ${dataHead.stiId}`, 8, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(8, 25, 194, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text(translate("Nama Consolidator"), 10, 32);
  doc.text(translate(":"), 36, 32);
  doc.text(ellipsisString(dataHead.consolidatorName, 70), 38, 32);

  doc.setTextColor("#4d4d4d");
  doc.text(translate("Kota Consolidator"), 10, 38);
  doc.text(translate(":"), 36, 38);
  doc.text(dataHead.originConsolidator, 38, 38);

  // middle column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT/Koli"), 80, 32);
  doc.text(":", 105, 32);
  doc.text(`${dataHead.totalStt}/${dataHead.totalPiece}`, 107, 32);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Berat Kotor"), 80, 38);
  doc.text(":", 105, 38);
  doc.text(
    `${convertDecimalWithComma(dataHead.totalGrossWeight, 2)} kg`,
    107,
    38
  );

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Tanggal Manifest"), 135, 32);
  doc.text(":", 170, 32);
  doc.text(
    `${formatDateNormal(dataHead.stiDate, "DD MMMM YYYY HH:mm:ss")}`,
    172,
    32
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT belum diterima"), 135, 38);
  doc.text(":", 170, 38);
  doc.text(dataHead.needToStiTotalStt.toString(), 172, 38);

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTables = [
    { header: "No.", dataKey: "no" },
    {
      header: "No. Bag",
      dataKey: "bagNo"
    },
    {
      header: "No. STT",
      dataKey: "sttNumber"
    },
    {
      header: "No. Referensi",
      dataKey: "refNo"
    },
    { header: translate("Jenis Pengiriman"), dataKey: "productType" },
    { header: translate("Total Koli"), dataKey: "piecesNumber" },
    { header: "STI - DEST", dataKey: "isStiDest" },
    { header: translate("Kota Tujuan"), dataKey: "destCity" },
    { header: translate("Wilayah"), dataKey: "regionDestName" },
    { header: translate("Penanda Paket"), dataKey: "statusReturn" }
  ];
  doc.autoTable({
    body: data,
    columns: columnsTables,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 43 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    didDrawCell: (cell: any) => {
      // top-border
      if (cell.section === "body") {
        doc.setFillColor("#e0e0e0");
        doc.rect(
          cell.row.cells[0].x + 0.05,
          cell.row.cells[0].y + cell.row.height,
          195,
          0.2,
          "F"
        );
        doc.setFillColor("#fff");
      }
    },
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text(translate("Staff Admin"), 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text(translate("Kurir"), 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text(translate("Operations Supervisor"), 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  doc.setProperties({
    title: `STI Manifest - ${dataHead.stiId}`
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const manifestNeedToSti = (
  dataHead: STIReverseScanDetail,
  data: Array<Record<string, string | number>>,
  logo: any
) => {
  doc = new jsPDF("p", "mm", "a4", true);

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`${translate("Daftar STT harus STI")}`, 8, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(8, 25, 194, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text(translate("Nama Consolidator"), 10, 32);
  doc.text(translate(":"), 36, 32);
  doc.text(ellipsisString(dataHead.consolidatorName, 70), 38, 32);

  doc.setTextColor("#4d4d4d");
  doc.text(translate("Kota Consolidator"), 10, 38);
  doc.text(translate(":"), 36, 38);
  doc.text(dataHead.originConsolidator, 38, 38);

  // middle column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT/Koli"), 80, 32);
  doc.text(":", 105, 32);
  doc.text(
    `${dataHead.needToStiTotalStt}/${dataHead.needToStiTotalPiece}`,
    107,
    32
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Berat"), 80, 38);
  doc.text(":", 105, 38);
  doc.text(
    `${convertDecimalWithComma(dataHead.needToStiTotalGrossWeight, 2)} kg`,
    107,
    38
  );

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Belum di STI pada waktu"), 130, 32);
  doc.text(":", 160, 32);
  doc.text(
    `${formatDateNormal(
      dataHead.needToStiCycleDate,
      "[Batas update] DD MMMM YYYY,"
    )}`,
    161,
    32
  );
  doc.text(`${formatDate(dataHead.needToStiCycleDate, "HH.mm")}`, 161, 35);

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTables = [
    { header: "No.", dataKey: "no" },
    {
      header: "No. Bag",
      dataKey: "bagNo"
    },
    {
      header: "No. STT",
      dataKey: "sttNo"
    },
    {
      header: "No. Referensi",
      dataKey: "refNo"
    },
    { header: translate("Jenis Pengiriman"), dataKey: "productType" },
    { header: translate("Total Koli"), dataKey: "totalPiece" },
    { header: translate("Kota Tujuan"), dataKey: "destCity" },
    { header: translate("Nama POS"), dataKey: "bookedName" }
  ];
  doc.autoTable({
    body: data,
    columns: columnsTables,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 43 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto",
      overflow: "ellipsize"
    },
    columnStyles: needToStiColumnStyles,
    didDrawCell: (cell: any) => {
      // top-border
      if (cell.section === "body") {
        doc.setFillColor("#e0e0e0");
        doc.rect(
          cell.row.cells[0].x + 0.05,
          cell.row.cells[0].y + cell.row.height,
          195,
          0.2,
          "F"
        );
        doc.setFillColor("#fff");
      }
    },
    willDrawCell: (cell: any) => {
      drawCellNeedToStiData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });

  doc.setProperties({
    title: `Daftar STT harus STI`
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const manifestAndMenifestNeedToSti = (
  dataHead: STIReverseScanDetail,
  data: Array<Record<string, string | number>>,
  needToStiData: Array<Record<string, string | number>>,
  logo: any
) => {
  doc = new jsPDF("p", "mm", "a4", true);

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(`${translate("STI Manifest")} - ${dataHead.stiId}`, 8, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(8, 25, 194, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text(translate("Nama Consolidator"), 10, 32);
  doc.text(translate(":"), 36, 32);
  doc.text(ellipsisString(dataHead.consolidatorName, 70), 38, 32);

  doc.setTextColor("#4d4d4d");
  doc.text(translate("Kota Consolidator"), 10, 38);
  doc.text(translate(":"), 36, 38);
  doc.text(dataHead.originConsolidator, 38, 38);

  // middle column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT/Koli"), 80, 32);
  doc.text(":", 105, 32);
  doc.text(`${dataHead.totalStt}/${dataHead.totalPiece}`, 107, 32);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Berat Kotor"), 80, 38);
  doc.text(":", 105, 38);
  doc.text(
    `${convertDecimalWithComma(dataHead.totalGrossWeight, 2)} kg`,
    107,
    38
  );

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Tanggal Manifest"), 135, 32);
  doc.text(":", 170, 32);
  doc.text(
    `${formatDateNormal(dataHead.stiDate, "DD MMMM YYYY HH:mm:ss")}`,
    172,
    32
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT belum diterima"), 135, 38);
  doc.text(":", 170, 38);
  doc.text(dataHead.needToStiTotalStt.toString(), 172, 38);

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  const columnsTables = [
    { header: "No.", dataKey: "no" },
    {
      header: "No. Bag",
      dataKey: "bagNo"
    },
    {
      header: "No. STT",
      dataKey: "sttNumber"
    },
    {
      header: "No. Referensi",
      dataKey: "refNo"
    },
    { header: translate("Jenis Pengiriman"), dataKey: "productType" },
    { header: translate("Total Koli"), dataKey: "piecesNumber" },
    { header: "STI - DEST", dataKey: "isStiDest" },
    { header: translate("Kota Tujuan"), dataKey: "destCity" },
    { header: translate("Wilayah"), dataKey: "regionDestName" },
    { header: translate("Penanda Paket"), dataKey: "statusReturn" }
  ];

  doc.autoTable({
    body: data,
    columns: columnsTables,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 43 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    didDrawCell: (cell: any) => {
      // top-border
      if (cell.section === "body") {
        doc.setFillColor("#e0e0e0");
        doc.rect(
          cell.row.cells[0].x + 0.05,
          cell.row.cells[0].y + cell.row.height,
          195,
          0.2,
          "F"
        );
        doc.setFillColor("#fff");
      }
    },
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text(translate("Staff Admin"), 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text(translate("Kurir"), 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text(translate("Operations Supervisor"), 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  if (needToStiData.length) {
    doc.addPage();

    doc.addImage(
      logo,
      "PNG",
      pdfWidth - imageWidth - 9,
      11,
      imageWidth,
      imageHeight,
      "logo",
      "FAST",
      0
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor("#4d4d4d");
    doc.text(`${translate("Daftar STT harus STI")}`, 8, 18);
    doc.setFillColor("#e0e0e0");
    doc.rect(8, 25, 194, 0.2, "F");
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setTextColor("#4d4d4d");

    // left column
    doc.setTextColor("#4d4d4d");
    doc.setFillColor("#ebecf0");
    doc.text(translate("Nama Consolidator"), 10, 32);
    doc.text(translate(":"), 36, 32);
    doc.text(ellipsisString(dataHead.consolidatorName, 70), 38, 32);

    doc.setTextColor("#4d4d4d");
    doc.text(translate("Kota Consolidator"), 10, 38);
    doc.text(translate(":"), 36, 38);
    doc.text(dataHead.originConsolidator, 38, 38);

    // middle column
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setTextColor("#4d4d4d");
    doc.text(translate("Total STT/Koli"), 80, 32);
    doc.text(":", 105, 32);
    doc.text(
      `${dataHead.needToStiTotalStt}/${dataHead.needToStiTotalPiece}`,
      107,
      32
    );

    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setTextColor("#4d4d4d");
    doc.text(translate("Berat"), 80, 38);
    doc.text(":", 105, 38);
    doc.text(
      `${convertDecimalWithComma(dataHead.needToStiTotalGrossWeight, 2)} kg`,
      107,
      38
    );

    // right column
    doc.setFont("helvetica", "normal");
    doc.setFontSize(7);
    doc.setTextColor("#4d4d4d");
    doc.text(translate("Belum di STI pada waktu"), 130, 32);
    doc.text(":", 160, 32);
    doc.text(
      `${formatDateNormal(
        dataHead.needToStiCycleDate,
        "[Batas update] DD MMMM YYYY,"
      )}`,
      161,
      32
    );
    doc.text(`${formatDate(dataHead.needToStiCycleDate, "HH.mm")}`, 161, 35);

    const needToStiDataLength = data.length;
    const needToStiColumnsTables = [
      { header: "No.", dataKey: "no" },
      {
        header: "No. Bag",
        dataKey: "bagNo"
      },
      {
        header: "No. STT",
        dataKey: "sttNo"
      },
      {
        header: "No. Referensi",
        dataKey: "refNo"
      },
      { header: translate("Jenis Pengiriman"), dataKey: "productType" },
      { header: translate("Total Koli"), dataKey: "totalPiece" },
      { header: translate("Kota Tujuan"), dataKey: "destCity" },
      { header: translate("Nama POS"), dataKey: "bookedName" }
    ];

    const needToStiPageNumber = doc.internal.getCurrentPageInfo().pageNumber;

    doc.autoTable({
      body: needToStiData,
      columns: needToStiColumnsTables,
      theme: "plain",
      startY:
        doc.internal.getCurrentPageInfo().pageNumber === needToStiPageNumber
          ? 43
          : 0,
      margin: { top: 7, left: 7, right: 7, bottom: 7 },
      headStyles: {
        font: "helvetica",
        fontStyle: "bold",
        fillColor: "#f5f6f7",
        textColor: "#1a1421",
        fontSize: 6,
        halign: "left",
        cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
      },
      bodyStyles: {
        font: "helvetica",
        fontStyle: "normal",
        textColor: "#1a1421",
        fontSize: 7,
        halign: "left",
        cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
        cellWidth: "auto",
        overflow: "ellipsize"
      },
      columnStyles: needToStiColumnStyles,
      didDrawCell: (cell: any) => {
        // top-border
        if (cell.section === "body") {
          doc.setFillColor("#e0e0e0");
          doc.rect(
            cell.row.cells[0].x + 0.05,
            cell.row.cells[0].y + cell.row.height,
            195,
            0.2,
            "F"
          );
          doc.setFillColor("#fff");
        }
      },
      willDrawCell: (cell: any) => {
        drawCellNeedToStiData(cell);
        lastPage = cell.pageNumber;
        lastY = cell.cursor.y;
        lastHeight = cell.row.height;
        if (
          cell.row.index === needToStiDataLength - 2 &&
          lastY + lastHeight + 20 > 270
        ) {
          cell.cell.contentHeight = 100;
          cell.row.height = 100;
        }
      }
    });
  }

  doc.setProperties({
    title: `STI Manifest - ${dataHead.stiId}`
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

const generateManifest = (printData: StiManifestData, logo: any) => {
  const data: Array<any> = [];
  let no = 1;
  for (const e of printData.stt) {
    data.push({
      no: no,
      bagNo: e.bagNo || "-",
      refNo: e.refNo || "-",
      sttNumber: `${e.sttNo} ${e.isDangerousGoods ? "    .." : ""}`,
      productType: e.sttProductType,
      piecesNumber: e.sttTotalPiece,
      destCity: e.stiDestCity,
      regionDestName: e.stiDestRegion || "-",
      isStiDest: e.isStiDest === 0 ? "Tidak" : "Ya",
      statusReturn: e.statusReturn || "-"
    });
    no++;
  }
  manifestPdfV2(printData, data, logo);
};

const generateManifestNeedToSti = (
  printData: STIReverseScanDetail,
  logo: any
) => {
  const data: Array<Record<string, string | number>> = [];

  printData.needToStiStt.forEach((stt, index) => {
    data.push({
      no: index + 1,
      bagNo: stt.bagNo || "-",
      sttNo: stt.sttNo,
      refNo: stt.refNo || "-",
      productType: stt.sttProductType,
      totalPiece: stt.sttTotalPiece,
      destCity: stt.sttDestinationCityId,
      bookedName: stt.bookedName || "-"
    });
  });

  manifestNeedToSti(printData, data, logo);
};

const generateManifestAndManifestNeedToSti = (
  printData: STIReverseScanDetail,
  logo: any
) => {
  const needToStiData: Array<Record<string, string | number>> = [];
  const data: Array<Record<string, string | number>> = [];

  printData.needToStiStt.forEach((stt, index) => {
    needToStiData.push({
      no: index + 1,
      bagNo: stt.bagNo || "-",
      sttNo: stt.sttNo,
      refNo: stt.refNo || "-",
      productType: stt.sttProductType,
      totalPiece: stt.sttTotalPiece,
      destCity: stt.sttDestinationCityId,
      bookedName: stt.bookedName || "-"
    });
  });

  printData.stt.forEach((e, index) => {
    data.push({
      no: index + 1,
      bagNo: e.bagNo || "-",
      refNo: e.refNo || "-",
      sttNumber: `${e.sttNo} ${e.isDangerousGoods ? "    .." : ""}`,
      productType: e.sttProductType,
      piecesNumber: e.sttTotalPiece,
      destCity: e.sttDestinationCityId,
      regionDestName: e.sttDestinationRegionName || "-",
      isStiDest: e.isStiDest === 0 ? "Tidak" : "Ya",
      statusReturn: e.statusReturn || "-"
    });
  });

  manifestAndMenifestNeedToSti(printData, data, needToStiData, logo);
};

export { generateManifestNeedToSti, generateManifestAndManifestNeedToSti };

export default generateManifest;
