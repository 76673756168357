
import {
  ellipsisString,
  formatDateNormal,
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { STIController } from "@/app/ui/controllers/STIController";
import generateSummaryManifest from "@/app/ui/modules/summary";
import { Vue } from "vue-class-component";
import generateManifest, {
  generateManifestAndManifestNeedToSti,
  generateManifestNeedToSti
} from "./manifest-pdf-reverse-scan";

export default class Generate extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  async print(id: number, logo: any) {
    // logo
    MainAppController.showLoading();

    const printManifest = await STIController.getStiManifestDetail({
      id,
      version: "v2",
      print: true
    });
    if (printManifest) {
      const data = STIController.stiManifestData;
      generateManifest(data, logo);
    }
    MainAppController.closeLoading();
  }

  async printSummary(id: number, logo: any) {
    // logo
    MainAppController.showLoading();

    const printManifest = await STIController.getStiManifestDetail({
      id,
      version: "v2",
      print: true
    });
    if (printManifest) {
      const dataHead = STIController.stiManifestData;

      const fields = [
        {
          name: "Kota Kedatangan",
          value: dataHead.originConsolidator,
        },
        {
          name: "Nama Consolidator",
          value: ellipsisString(dataHead.consolidatorName, 21),
        },
        {
          name: "Tanggal Manifest",
          value: formatDateNormal(dataHead.stiDate, "DD MMMM YYYY HH:mm:ss"),
        },
        {
          name: "Total STT/Koli",
          value: `${dataHead.totalStt}/${dataHead.totalPiece}`,
        },
        {
          name: "Segera POD",
          value: `${
            dataHead.statusReturnPOD === 0 ? "Tidak" : dataHead.statusReturnPOD
          }`,
        },
        {
          name: "Segera RTS",
          value: `${
            dataHead.statusReturnRTS === 0 ? "Tidak" : dataHead.statusReturnRTS
          }`,
        },
        {
          name: "Segera HAL",
          value: `${
            dataHead.statusReturnHAL === 0 ? "Tidak" : dataHead.statusReturnHAL
          }`,
        },
      ];
      generateSummaryManifest(`STI Manifest - ${dataHead.stiId}`, fields, logo);
    }
    MainAppController.closeLoading();
  }

  async printSttNeedSti(id: number, logo: any) {
    // logo
    MainAppController.showLoading();

    const printManifest = await STIController.getDetailSTIReverseScan(id);
    if (printManifest) {
      const data = STIController.STIReverseScanDetailData;
      generateManifestNeedToSti(data, logo);
    }
    MainAppController.closeLoading();
  }

  async printManifestAndSttNeedSti(id: number, logo: any) {
    // logo
    MainAppController.showLoading();

    const printManifest = await STIController.getDetailSTIReverseScan(id);
    if (printManifest) {
      const data = STIController.STIReverseScanDetailData;
      generateManifestAndManifestNeedToSti(data, logo);
    }
    MainAppController.closeLoading();
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
